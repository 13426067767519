import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { Galleria } from 'primereact/galleria';

function Galeria() {
  
  const [products, setProducts] = useState(['logo.webp','pek1.webp','pek2.webp','pek3.webp','pek4.webp','mis1.webp','pek5.webp','pek6.webp','pek7.webp','pek8.webp','pek9.webp','pek10.webp','pek11.webp']);

  const [images, setImages] = useState([{
    itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1.webp',
    thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1s.webp',
    alt: 'Description for Image 1',
    title: 'Title 1'
  }]);

  useEffect(() => {
    let imgEx = products.map((x, index) => {
        return {
          itemImageSrc: `/${x}`,
          thumbnailImageSrc: `/${x}`,
          alt: `Mascota ${index}`,
          title: `Mascota ${index}`
      };
    });
    setImages(imgEx)

  }, [])


    const [activeIndex, setActiveIndex] = useState(0);    
    const galleria = useRef<Galleria>(null);

    const itemTemplate = (item:any) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '50%', display: 'block' }} />;
    }

    const thumbnailTemplate = (item:any) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
    }


  return (
    <div className="flex flex-column pt-0 lg:pt-2 xl:pt-2 w-12">          
          <div className="flex justify-content-center w-12">
            <h1 style={{color:'white'}}>Galeria</h1>  
          </div>
            <Galleria ref={galleria} value={images} numVisible={7}
            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
            circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
            <div className="flex flex-wrap justify-content-center align-items-center w-12" >
            {
                galleria && images && images.map((image, index) => {
                    let imgEl = <img src={image.thumbnailImageSrc} alt={image.alt}  style={{ cursor: 'pointer', maxWidth:'439px', maxHeight:'439.23px' }} onClick={
                        () => {setActiveIndex(index); galleria!.current!.show()}
                    } />
                    return (
                        <div className="flex w-auto justify-content-center align-items-center" key={index} style={{ background: 'black', minWidth:'439px', minHeight:'439.23px' }} >
                            {imgEl}
                        </div>
                    )
                })
            }
        </div>
      
    </div>
  );
}

export default Galeria;

import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import CarouselEx from './CarouselEx';
import { Badge } from 'primereact/badge';
import { Link } from 'react-router-dom';


function Galeria() {
  
   

      const [products, setProducts] = useState(['logo.webp','pek1.webp','pek2.webp','pek3.webp','pek4.webp']);
      // const [products, setProducts] = useState(['logo.webp','pek1.webp','pek2.webp','pek3.webp','pek4.webp','pek5.webp','pek6.webp','pek7.webp','pek8.webp','pek9.webp','pek10.webp','pek11.webp','mis1.webp']);
    
      const productsEx = products.map((x, index) => (
        <div  key={index} >
          <img
            className="App-logo"
            alt="Baño y estética canina para perros en Estética Canina Shihtzumania"
            src={x}
          />
        </div>
        ));

        
      const container2 = (
        <div className="flex flex-wrap justify-content-center h-16rem" style={{ background: 'white' }}>
            <div className="hidden xl:flxex lg:flex flex-column align-items-end justify-content-top w-12 lg:w-4 xl:w-4 ">
              <div className="w-12 lg:w-6 xl:w-6">
                <img
                  className=' h-16rem'
                  src='/logo.webp'
                  alt="First slide"
                />
              </div>
            </div>
            <div className="flex flex-column align-items-center xl:align-items-start lg:align-items-start justify-content-center w-12 lg:w-8 xl:w-8 pl-0 lg:pl-5 xl:pl-5">
              <h1>Agenda tu cita!</h1>
              <div>Servicio de recolecta. Cotiza el costo de tu traslado con tu ubicación al momento de realizar la reserva.</div>
              <div><i className="pi pi-phone" style={{ color: '#fe0072b5' }}></i>  <Link to={'https://wa.me/+529995739375'} style={{textDecoration: 'none',color: 'black'}}>99-95-73-93-75</Link></div>
              <div><i className="pi pi-whatsapp" style={{ color: '#fe0072b5' }}></i>  <Link to={'https://wa.me/+529995739375'} style={{textDecoration: 'none',color: 'black'}}>99-95-73-93-75</Link></div>
              <div><i className="pi pi-facebook" style={{ color: '#fe0072b5' }}></i> <Link to={'https://www.facebook.com/profile.php?id=100089866844097'} style={{textDecoration: 'none',color: 'black'}}>Estetica Canina ShihTzumania</Link> </div>
              <div><i className="pi pi-instagram" style={{ color: '#fe0072b5' }}></i> <Link to={'https://www.instagram.com/shih_tzumania'} style={{textDecoration: 'none',color: 'black'}}>Shih-Tzumania</Link> </div>
              <div><i className="pi pi-tiktok" style={{ color: '#fe0072b5' }}></i>  <Link to={'https://www.tiktok.com/@esteticacanshihtzumania'} style={{textDecoration: 'none',color: 'black'}}>@esteticanshihtzumania</Link> </div>
            </div>
        </div>
      );


  return (
    <div className="pt-0 lg:pt-2 xl:pt-2">
      <header className="App-header">
        <div className="flex flex-wrap-reverse xl:flex-wrap lg:flex-wrap justify-content-center w-12">
            <div className="flex flex-column align-items-center justify-content-top w-12 lg:w-3 xl:w-3">
              <div className='w-12 lg:w-9 xl:w-9 min-h-20rem' style={{ background: 'rgb(125 4 58 / 71%)' }}>
                <CarouselEx children={productsEx} />
              </div>
              <div className='flex flex-wrap w-12 lg:w-9 xl:w-9 align-items-center text-center' style={{ background: 'rgb(125 4 58 / 71%)' }}>
                <div className='w-4'><img src="/BBBVN-0005.png" alt="Baño y estética canina para perros en Estética Canina Shihtzumania" className="max-w-3rem" /></div>
                <div className='w-4'><img src="/BBLMC-0004.png" alt="Corte de pelo personalizado para perros en Estética Canina Shihtzumania" className="max-w-3rem" /></div>
                <div className='w-4'><img src="/BLANCO-0003.png" alt="Baño y estética canina para perros en Estética Canina Shihtzumania" className="max-w-3rem" /></div>
              </div>
            </div>
            <div className="flex flex-column align-items-center justify-content-center w-9">
              <h1>Estética Canina en Mérida - Servicio de Aseo y Corte para Perros</h1>
              <div>En Estética Canina Shihtzumania, nos especializamos en <strong>estética canina</strong> para perros, ofreciendo servicios de <strong>aseo completo</strong>, <strong>corte de pelo</strong> y <strong>peinado personalizado</strong>. Nuestro objetivo es mantener a tu mascota limpia y con un pelaje hermoso.</div>
              <ul className='list-none'>
                <li><i className="pi pi-sparkles"></i> Servicios de Estética Canina</li>
                <li><i className="pi pi-sparkles"></i> Baño y Corte de Perros</li>
                <li><i className="pi pi-sparkles"></i> Peinados y Estilos para Mascotas</li>
                <li><i className="pi pi-sparkles"></i> Cuidado de Uñas y Orejas</li>
                <li><i className="pi pi-sparkles"></i> Tratamientos Especiales para el Pelaje</li>
              </ul>
              <div className='mt-3' style={{fontSize: '18px'}}>Ofrecemos <strong>cuidado de uñas</strong> y <strong>limpieza de orejas</strong>, así como <strong>tratamientos especiales</strong> para el pelaje. Nuestros servicios están diseñados para proporcionar el mejor <strong>grooming canino</strong> en Mérida.</div>
            </div>
        </div>
      </header>
      <div className='footerEx flex align-items-center justify-content-center'>
          <div className='textFooter'><h2>Servicio de peluquería canina en Mérida, Yucatán.</h2></div>
      </div>
      {container2}
    </div>
  );
}

export default Galeria;

import React, { useEffect, useState } from 'react';
import './App.css';
import { Menu } from 'primereact/menu';
import { InputText } from 'primereact/inputtext';
import { Avatar } from 'primereact/avatar';
import { Menubar } from 'primereact/menubar';
import { Badge } from 'primereact/badge';
import CarouselEx from './CarouselEx';
import { createBrowserRouter, Link, RouterProvider } from 'react-router-dom';
import Galeria from './Galeria';
import HeaderEx from './HeaderEx';
import Estetica from './Estetica';
import Contacto from './Contacto';

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const removeLoader = () => setLoading(false);
    if (document.readyState === "complete") {
      removeLoader();
    } else {
      window.addEventListener('load', removeLoader);
      return () => window.removeEventListener("load", removeLoader);
    }
  }, []);
  
  const itemRenderer = (item:any) => (
    <a className="flex align-items-center p-menuitem-link" href='/'>
          <span className={item.icon} />
          <span className="mx-2">{item.label}</span>
          {/* {item.badge && <Badge className="ml-auto" value={item.badge} />} */}
          {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
      </a>
  );
  const items = [
    {
      label: 'Inicio',
      icon: 'pi pi-rosa pi-home',
      url: '/'
    },
    {
        label: 'Estetica Canina',
        icon: 'pi pi-rosa pi-sparkles',
        url: '/estetica'
    },
    {
        label: 'Galeria',
        icon: 'pi-rosa pi pi-image',
        url: '/galeria'
    },
    {
        label: 'Contacto',
        icon: 'pi pi-rosa pi-envelope',
        url: '/contacto'
    }
  ];

  const start = <img alt="logo" src="/minilogo.png" height="40" className="mr-2"></img>;
  const end = (
      <div className="flex align-items-center gap-2">
        <i className="pi pi-phone" style={{ color: '#fe0072b5' }}></i> 99-95-73-93-75
      </div>
  );

  const container2 = (
    <div className="flex flex-wrap justify-content-center h-16rem" style={{ background: 'white' }}>
        <div className="hidden xl:flxex lg:flex flex-column align-items-end justify-content-top w-12 lg:w-4 xl:w-4 ">
          <div className="w-12 lg:w-6 xl:w-6">
            <img
              className=' h-16rem'
              src='/logo.webp'
              alt="First slide"
            />
          </div>
        </div>
        <div className="flex flex-column align-items-center xl:align-items-start lg:align-items-start justify-content-center w-12 lg:w-8 xl:w-8 pl-0 lg:pl-5 xl:pl-5">
          <h1>Agenda tu cita!</h1>
          <div>Servicio de recolecta. Cotiza el costo de tu traslado con tu ubicación al momento de realizar la reserva.</div>
        </div>
    </div>
  );

  const Loading = () => {
    return (
      <div className="kapono">
        <img alt="Estética Canina Shihtzumania en Mérida" src="/minilogo.png" />
      </div>
    );
  };
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HeaderEx />,
    },
    {
      path: "galeria",
      element: <Galeria />,
    },
    {
      path: "estetica",
      element: <Estetica />,
    },
    {
      path: "contacto",
      element: <Contacto />,
    },
  ]);

  return (
    <div className="App pt-0 lg:pt-2 xl:pt-2">
      <Menubar className='m-0 lg:m-3 xl:m-3 mb-0 lg:mb-5 xl:mb-5' model={items} start={start} end={end} />
      {loading && <Loading />}
      <RouterProvider router={router} />
      
      <div className='footerEx flex align-items-center justify-content-center' style={{background: '#620932', }}>
          <div className='textFooter'>&copy; 2024 Estética Canina Shihtzumania</div>
      </div>
    </div>
  );
}

export default App;

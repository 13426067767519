import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { Galleria } from 'primereact/galleria';
import CarouselEx from './CarouselEx';

function Galeria() {
  
  const [products, setProducts] = useState(['pek1.webp','pek2.webp','pek3.webp','pek4.webp']);
 
  const [images, setImages] = useState([{
    itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1.webp',
    thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1s.webp',
    alt: 'Description for Image 1',
    title: 'Title 1'
  }]);

  useEffect(() => {
    let imgEx = products.map((x, index) => {
        return {
          itemImageSrc: `/${x}`,
          thumbnailImageSrc: `/${x}`,
          alt: `Mascota ${index}`,
          title: `Mascota ${index}`
      };
    });
    setImages(imgEx)

  }, [])


    const [activeIndex, setActiveIndex] = useState(0);    
    const galleria = useRef<Galleria>(null);

    const itemTemplate = (item:any) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{width: '400px', maxWidth: '400px', maxHeight: '400px', display: 'block' }} />;
    }

    const thumbnailTemplate = (item:any) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
    } 
    const productsEx = products.map((x) => (
      <div>
        <img
          className="App-logo"
          src={x}
          alt="First slide"
        />
      </div>
      ));
  return (
    <div className="pt-0 lg:pt-2 xl:pt-2">
      <header className="App-estetica">
        <div className="flex flex-wrap-reverse xl:flex-wrap lg:flex-wrap justify-content-center w-12">
            <div className="flex flex-column align-items-center justify-content-center w-9">
              <h1>Peluquería para mascotas</h1>
              <div>En Estética Canina Shihtzumania, nos especializamos en <strong>estética canina</strong> para perros, ofreciendo servicios de <strong>aseo completo</strong>, <strong>corte de pelo</strong> y <strong>peinado personalizado</strong>. Nuestro objetivo es mantener a tu mascota limpia y con un pelaje hermoso.</div>
            </div>
        </div>
      </header>
      <div className="flex flex-wrap xl:flex-wrap lg:flex-wrap justify-content-center w-12 pb-5" style={{background: 'white'}}>
          <div className='flex flex-wrap xl:flex-wrap lg:flex-wrap justify-content-center w-12'>
            <h1>¿Qué ofrecemos?</h1>
          </div>
          <div className="flex flex-wrap align-items-center justify-content-center w-9"> 
            <div className='flex flex-column w-12 xl:w-6 lg:w-6'>
              <div><strong style={{color: 'white'}}>Baños y Aseo Completo</strong></div>
              <div style={{textAlign: 'justify'}}>Utilizamos productos de alta calidad que cuidan la piel y el pelaje de tu perro, dejándolos frescos, limpios y suaves.</div>

              <div><strong style={{color: 'white'}}>Corte y Peinado Personalizado</strong></div>
              <div style={{textAlign: 'justify'}}>Cada perro es único. Ofrecemos cortes y estilos personalizados que se adaptan a las necesidades específicas y la personalidad de tu mascota.</div>

              <div><strong style={{color: 'white'}}>Cuidado de Uñas y Orejas</strong></div>
              <div style={{textAlign: 'justify'}}>Mantenemos las uñas de tu perro en su longitud óptima y cuidamos de sus orejas para evitar infecciones y mantenerlas saludables.</div>

              <div><strong style={{color: 'white'}}>Tratamientos Especiales</strong></div>
              <div style={{textAlign: 'justify'}}>Desde tratamientos hidratantes hasta mascarillas para el pelaje, ofrecemos opciones adicionales para mantener a tu perro en su mejor forma.</div>

              <div><strong style={{color: 'white'}}>Ambiente Amigable y Relajado</strong></div>
              <div style={{textAlign: 'justify'}}>En Estética Canina Shihtzumania, sabemos que la experiencia de la estética puede ser estresante para algunos perros. Por eso, nuestro espacio está diseñado para ser acogedor y libre de estrés, asegurando que cada visita sea lo más agradable posible para tu mascota.</div>

            </div>
            <div className='flex w-12 xl:w-6 lg:w-6 align-items-center justify-content-center flex-column'>
              <ul className='list-none'>
                  <li><i className="pi pi-sparkles"></i> Servicios de Estética Canina</li>
                  <li><i className="pi pi-sparkles"></i> Baño y Corte de Perros</li>
                  <li><i className="pi pi-sparkles"></i> Peinados y Estilos para Mascotas</li>
                  <li><i className="pi pi-sparkles"></i> Cuidado de Uñas y Orejas</li>
                  <li><i className="pi pi-sparkles"></i> Tratamientos Especiales para el Pelaje</li>
                </ul>
                <img src='/pek8.webp' alt="Estética Canina Shihtzumania" style={{maxWidth: "300px"}}/>
            </div>
          </div>
      </div>
      <div className='footerEx flex align-items-center justify-content-center'>
          <div className='textFooter'><h2>Servicio de peluquería canina en Mérida, Yucatán.</h2></div>
      </div>
      <div className="flex flex-wrap xl:flex-wrap lg:flex-wrap justify-content-center w-12 pb-5" style={{background: '#d52173', color: 'white'}}>
          <div className='flex flex-wrap xl:flex-wrap lg:flex-wrap justify-content-center w-12'>
            <h1>¿Por qué elegirnos?</h1>
          </div>
          <div className="flex flex-wrap align-items-center justify-content-center w-9"> 
            <div className='flex flex-column w-12 xl:w-6 lg:w-6'>
              <div><strong style={{color: 'white'}}>Profesionalismo y Experiencia</strong></div>
              <div style={{textAlign: 'justify'}}>Nuestro equipo cuenta con una amplia experiencia en el cuidado y estética canina, garantizando resultados excepcionales.</div>

              <div><strong style={{color: 'white'}}>Corte y Peinado Personalizado</strong></div>
              <div style={{textAlign: 'justify'}}>Nos tomamos el tiempo para conocer a tu perro y entender sus necesidades, ofreciendo un servicio a medida.</div>

              <div><strong style={{color: 'white'}}>Atención Personalizada</strong></div>
              <div style={{textAlign: 'justify'}}>Mantenemos las uñas de tu perro en su longitud óptima y cuidamos de sus orejas para evitar infecciones y mantenerlas saludables.</div>

              <div><strong style={{color: 'white'}}>TPasión por los Animales</strong></div>
              <div style={{textAlign: 'justify'}}>Amamos lo que hacemos y eso se refleja en cada detalle del servicio que ofrecemos.</div>

            </div>
            <div className='flex w-12 xl:w-6 lg:w-6 align-items-center justify-content-center'>
              <img src='/pek5.webp' alt="Estética Canina Shihtzumania" style={{maxWidth: "300px"}}/>
            </div>
          </div>
      </div>
      <div className="flex flex-wrap xl:flex-wrap lg:flex-wrap justify-content-center w-12 pb-5" style={{color: 'white', background: 'rgb(14 14 14 / 76%)'}}>
          <div className='flex flex-wrap xl:flex-wrap lg:flex-wrap justify-content-center w-12'>
            <h1>Tarifas</h1>
          </div>
          <div className="flex flex-wrap align-items-center justify-content-center w-9"> 
            <div className='flex flex-column w-12 xl:w-6 lg:w-6'>
              <div>
                <strong style={{color: 'white'}}> Servicio de baño para perros</strong>
                <div>Incluye</div>
                  
                <ul className='list-none '>
                  <li><i className="pi pi-sparkles"></i> Baño</li>
                  <li><i className="pi pi-sparkles"></i> Cepillado</li>
                  <li><i className="pi pi-sparkles"></i> Arreglo higiénico</li>
                  <li><i className="pi pi-sparkles"></i> Corte de uñas</li>
                  <li><i className="pi pi-sparkles"></i> Limpieza de orejas</li>
                  <li><i className="pi pi-sparkles"></i> Adorno</li>
                  <li><i className="pi pi-sparkles"></i> Hidratación de almohadillas y nariz</li>
                  <li><i className="pi pi-sparkles"></i> Perfum</li>
                </ul>
              </div>
              <div className='divider'></div>
              <div className='flex flew-wrap'>
                <div className='w-12 xl:w-6 lg:w-6'> 
                    <div><h2>Ba&ntilde;os</h2></div>
                    <div><i className="pi pi-sparkles"></i> Razas Mini $120</div>
                    <div><i className="pi pi-sparkles"></i> Razas pequeñas $150</div>
                    <div><i className="pi pi-sparkles"></i> Razas medianas $180</div>
                    <div><i className="pi pi-sparkles"></i> Razas grandes $220</div>
                    <div><i className="pi pi-sparkles"></i> Razas extra grandes $300</div>
                </div>
                <div className='w-12 xl:w-6 lg:w-6'> 
                    <div><h2>Cortes</h2></div>
                    <div><i className="pi pi-sparkles"></i> Razas Mini $150</div>
                    <div><i className="pi pi-sparkles"></i> Razas pequeñas $180</div>
                    <div><i className="pi pi-sparkles"></i> Razas medianas $200</div>
                    <div><i className="pi pi-sparkles"></i> Razas grandes $250</div>
                    <div><i className="pi pi-sparkles"></i> Razas extra grandes $350</div>
                </div>
              </div>
              <div className='mb-3 xl:mb-0 lg:mb-0' style={{fontSize: 12}}>
                *Nuestras tarifas tienen variaciones dependiendo del largo del pelo, técnica realizada y frecuencia de las visitas.
              </div>
            </div>
            <div className='w-12 lg:w-3 xl:w-3 min-h-20rem lg:ml-3 xl:ml-3' style={{ background: 'rgb(125 4 58 / 71%)' }}>
              <CarouselEx children={productsEx} />
            </div>
          </div>
      </div>
    </div>
  );
}

export default Galeria;

import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { Galleria } from 'primereact/galleria';
import CarouselEx from './CarouselEx';
import { Link } from 'react-router-dom';

function Contacto() {
  
  const [products, setProducts] = useState(['pek1.webp','pek2.webp','pek3.webp','pek4.webp']);
 
  const [images, setImages] = useState([{
    itemImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1.webp',
    thumbnailImageSrc: 'https://primefaces.org/cdn/primereact/images/galleria/galleria1s.webp',
    alt: 'Description for Image 1',
    title: 'Title 1'
  }]);

  useEffect(() => {
    let imgEx = products.map((x, index) => {
        return {
          itemImageSrc: `/${x}`,
          thumbnailImageSrc: `/${x}`,
          alt: `Mascota ${index}`,
          title: `Mascota ${index}`
      };
    });
    setImages(imgEx)

  }, [])


    const [activeIndex, setActiveIndex] = useState(0);    
    const galleria = useRef<Galleria>(null);

    const itemTemplate = (item:any) => {
        return <img src={item.itemImageSrc} alt={item.alt} style={{width: '400px', maxWidth: '400px', maxHeight: '400px', display: 'block' }} />;
    }

    const thumbnailTemplate = (item:any) => {
        return <img src={item.thumbnailImageSrc} alt={item.alt} style={{ display: 'block' }} />;
    } 
    const productsEx = products.map((x) => (
      <div>
        <img
          className="App-logo"
          src={x}
          alt="First slide"
        />
      </div>
      ));
  return (
    <div className="pt-0 lg:pt-2 xl:pt-2">
      <header className="App-header">
        <div className="flex flex-wrap-reverse xl:flex-wrap lg:flex-wrap justify-content-center w-12">
            <div className="flex flex-column align-items-center justify-content-center w-9">
              <h1>Información de Contacto</h1>              
              <img src='/pek10.webp' alt="Estética Canina Shihtzumania" style={{maxWidth: "300px"}}/>
              <div><strong>Dirección:</strong> 19 #81-C por 12 y 6 Comisaria Caucel</div>
              <div><strong>Teléfono:</strong> 99-95-73-93-75</div>             
              <div><strong>WhatsApp:</strong>  <Link to={'https://wa.me/+529995739375'} style={{textDecoration: 'none',color: 'white'}}>99-95-73-93-75</Link></div>              
              <div><strong><i className="pi pi-facebook" style={{ color: 'white' }}></i> <Link to={'https://www.facebook.com/profile.php?id=100089866844097'} style={{textDecoration: 'none',color: 'white'}}>Estetica Canina ShihTzumania</Link></strong> </div>
              <div><strong><i className="pi pi-instagram" style={{ color: 'white' }}></i> <Link to={'https://www.instagram.com/shih_tzumania'} style={{textDecoration: 'none',color: 'white'}}>Shih-Tzumania</Link></strong> </div>
              <div><strong><i className="pi pi-tiktok" style={{ color: 'white' }}></i>  <Link to={'https://www.tiktok.com/@esteticacanshihtzumania'} style={{textDecoration: 'none',color: 'white'}}>@esteticanshihtzumania</Link></strong> </div>
              {/* <div><strong>Correo Electrónico:</strong> <a href="mailto:[correo@ejemplo.com]">citas@shihtzumania.com.mx</a></div> */}
              <div><strong>Horario:</strong> Lunes a Sabado: 5:00 PM - 9:00 PM, Domingo: previa cita</div>
            </div>
        </div>
      </header>
    </div>
  );
}

export default Contacto;
